<template>
    <v-container class="pa-8">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4 text-center">
            <v-icon>mdi-bell</v-icon>
            Activity Logs
        </div>
        <v-card elevation="0" class="mx-auto" width="500">
                <v-list v-show="returnLogs" three-line >
                    <v-btn color="primary" block text @click="previous" v-show="!prev_btn">show previous activity logs</v-btn>
                    <v-list-item-group>
                        <v-list-item link v-show="ActivityLogs" v-for="logs in returnLogs" :key="logs['.key']">
                        <v-list-item-avatar>
                            <v-img :src="logs.photoURL"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-html="logs.action"></v-list-item-title>
                            <v-list-item-subtitle class="text-caption" v-html="logs.displayName"></v-list-item-subtitle>
                            <!-- <v-list-item-subtitle class="text-caption">{{$moment(logs.timestamp.toDate()).fromNow()}}</v-list-item-subtitle> -->
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>{{$moment(logs.timestamp.toDate()).fromNow()}}</v-list-item-action-text>
                        </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                    <v-btn color="primary" block text @click="next" v-show="!next_btn">show more activity logs</v-btn>
                </v-list>
        </v-card>   
    </v-container>
</template>
<script>
export default {
    data(){
        return {
            ActivityLogs: [],
            limit: 10,
            lastVisible: '',
            firstVisible: '',
            next_btn: false,
            prev_btn: true            
        }
    },
    watch: {
      'ActivityLogs': function(newN) {
        let logsUpdate = newN.slice(0,10)
        logsUpdate.forEach(a=>{
          if(a.deptSeen === undefined || a.RMTSeen === undefined){
            this.updateNotif(a)
          }
        })
      },
    },
    computed:{
        returnLogs(){
            let user = this.$store.getters['useraccount/isAuthenticated']
            if(user.department !== 'RISK MANAGEMENT TEAM'){
                return this.ActivityLogs.filter(a=>{
                return a.department == user.department
                })
            }

            return this.ActivityLogs
        },
    },
    mounted(){
        this.$binding("ActivityLogs", this.$db.collection("ActivityLogs").orderBy('timestamp','desc').limit(this.limit))
        .then((ActivityLogs) => {
            console.log(ActivityLogs,'ActivityLogs') // => __ob__: Observer
        })
        
        this.$db.collection('ActivityLogs').orderBy('timestamp','desc').limit(this.limit).get().then(documentSnapshots => {
        this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1]
        this.firstVisible = documentSnapshots.docs[0]
        }).then(() => {
                    // peep to check if next should be on or off
                    this.$db.collection('ActivityLogs').orderBy('timestamp','desc').startAfter(this.lastVisible).limit(this.limit).get()
                        .then(snap => {
                            if (snap.size === 0) {
                                this.next_btn = true
                            }
                        })
                })
    },
    methods:{
      updateNotif(notif){
        let user = this.$store.getters['useraccount/isAuthenticated']
        if(user.department !== 'RISK MANAGEMENT TEAM'){
            this.$db.collection('ActivityLogs').doc(notif['.key']).update({
                deptSeen: true
            })
        } else {
            this.$db.collection('ActivityLogs').doc(notif['.key']).update({
                RMTSeen: true
            })            
        } 
      },
      next () {
      if (!this.next_btn) {
      // bind data with logs
        this.$binding('ActivityLogs', this.$db.collection('ActivityLogs').orderBy('timestamp','desc').startAfter(this.lastVisible).limit(this.limit))
        // set last and first visible items
        this.$db.collection('ActivityLogs').orderBy('timestamp','desc').startAfter(this.lastVisible).limit(this.limit).get().then(documentSnapshots => {
        this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1]
        this.firstVisible = documentSnapshots.docs[0]
        }).then(() => {
        // Peep  on the next  next query to see if it gives zero
        this.$db.collection('ActivityLogs').orderBy('timestamp','desc').startAfter(this.lastVisible).limit(this.limit).get()
          .then(snap => {
          if (snap.size === 0) {
            //disable button if the next peeped result gets zero
            this.next_btn = true
            // enable previous button
            this.prev_btn = false
          } else {
            // enable next button if peeped result is not zero
            this.next_btn = false
            // enable previous button
            this.prev_btn = false
          }
          })
        })
      }
      },
      previous () {
      // Ensure previous is not zero
      this.$db.collection('ActivityLogs').orderBy('timestamp','desc').endBefore(this.firstVisible).limitToLast(this.limit).get().then(snap => { return snap.size })
      .then(size => {
        //confirm is not zero here
        if (size !== 0) {
        //bind the previous to ActivityLogs
        this.$binding('ActivityLogs', this.$db.collection('ActivityLogs').orderBy('timestamp','desc').endBefore(this.firstVisible).limitToLast(this.limit))
        // Set last and first visible
        this.$db.collection('ActivityLogs').orderBy('timestamp','desc').endBefore(this.firstVisible).limitToLast(this.limit).get().then(documentSnapshots => {
          this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1]
          this.firstVisible = documentSnapshots.docs[0]
        }).then(() => {
          // peep the next previous query
          this.$db.collection('ActivityLogs').orderBy('timestamp','desc').endBefore(this.firstVisible).limitToLast(this.limit).get()
          .then(snap => {
            if (snap.size === 0) {
            //if next peeped previous button gets 0 disable
            this.prev_btn = true
            this.next_btn = false
            } else {
            //if next peeped result is does not get 0 enable buttons
            this.prev_btn = false
            this.next_btn = false
            }
          })
        })
        }
      })
      }
    }    
}
</script>